export const country_region = [
	{ value: "en_US", label: "США - Английский" },
  { value: "es_AR", label: "Аргентина - Испанский" },
	{ value: "en_AW", label: "Аруба - Английский" },
	{ value: "en_AU", label: "Австралия - Английский" },
	{ value: "de_AT", label: "Австрия - Немецкий" },
	{ value: "en_BS", label: "Багамские острова - Английский" },
	{ value: "en_BD", label: "Бангладеш - Английский" },
	{ value: "en_BB", label: "Барбадос - Английский" },
	{ value: "nl_BE", label: "Бельгия - Голландский" },
	{ value: "fr_BE", label: "Бельгия - Французский" },
	{ value: "es_BO", label: "Боливия - Испанский" },
	{ value: "pt_BR", label: "Бразилия - Португальский" },
	{ value: "en_BG", label: "Болгария - Английский" },
  { value: "en_CA", label: "Канада - Английский" },
	{ value: "fr_CA", label: "Канада - Французский" },
	{ value: "es_CL", label: "Чили - Испанский" },
	{ value: "zh_CN", label: "Китай - Упрощенный китайский" },
	{ value: "es_CO", label: "Колумбия - Испанский" },
	{ value: "es_CR", label: "Коста-Рика - Испанский" },
	{ value: "cs_CZ", label: "Чешская Республика - Чешский" },
	{ value: "da_DK", label: "Дания - Датский" },
	{ value: "es_DO", label: "Доминиканская Республика - Испанский" },
  { value: "es_EC", label: "Эквадор - Испанский" },
	{ value: "en_EG", label: "Египет - Английский" },
	{ value: "es_SV", label: "Сальвадор - Испанский" },
    { value: "en_EE", label: "Эстония - Английский"},
	{ value: "fi_FI", label: "Финляндия - Финский" },
	{ value: "fr_FR", label: "Франция - Французский" },
	{ value: "de_DE", label: "Германия - Немецкий" },
	{ value: "el_GR", label: "Греция - Греческий" },
	{ value: "en_GD", label: "Гренада - Английский" },
	{ value: "es_GT", label: "Гватемала - Испанский" },
  { value: "es_HN", label: "Гондурас - Испанский" },
	{ value: "en_HK", label: "Гонконг - Английский" },
	{ value: "zhtr_HK", label: "Гонконг - Китайский (традиционное письмо)" },
	{ value: "hu_HU", label: "Венгрия - Венгерский" },
	{ value: "en_IN", label: "Индия - Английский" },
	{ value: "en_ID", label: "Индонезия - Английский" },
	{ value: "in_ID", label: "Индонезия - Индонезийский" },
	{ value: "en_IE", label: "Ирландия - Английский" },
	{ value: "en_IL", label: "Израиль - Английский" },
	{ value: "it_IT", label: "Италия - Итальянский" },
  { value: "en_JM", label: "Ямайка - Английский" },
	{ value: "ja_JP", label: "Япония - Японский" },
	{ value: "ko_KR", label: "Корея - Корейский" },
	{ value: "en_KW", label: "Кувейт - Английский" },
    { value: "en_LV", label: "Латвия - Английский"},
	{ value: "en_LB", label: "Ливан - английский" },
    { value: "en_LT", label: "Литва - Английский"},
	{ value: "fr_LU", label: "Люксембург - Французский" },
  { value: "en_MY", label: "Малайзия - Английский" },
	{ value: "es_MX", label: "Мексика - Испанский" },
	{ value: "en_MA", label: "Марокко - Английский" },
	{ value: "nl_NL", label: "Нидерланды - Нидерландский" },
	{ value: "en_NZ", label: "Новая Зеландия - Английский" },
	{ value: "es_NI", label: "Никарагуа - Испанский" },
	{ value: "no_NO", label: "Норвегия - Норвежский" },
  { value: "en_PK", label: "Пакистан - Английский" },
	{ value: "es_PA", label: "Панама - Испанский" },
	{ value: "es_PY", label: "Парагвай - Испанский" },
	{ value: "es_PE", label: "Перу - Испанский" },
	{ value: "en_PH", label: "Филиппины - Английский" },
	{ value: "pl_PL", label: "Польша - Польский" },
	{ value: "pt_PT", label: "Португалия - Португальский" },
	{ value: "es_PR", label: "Пуэрто-Рико - Испанский" },
  { value: "en_RO", label: "Румыния - Английский" },
	{ value: "ru_RU", label: "Россия - Русский" },
	{ value: "en_SA", label: "Саудовская Аравия - Английский" },
	{ value: "en_SG", label: "Сингапур - Английский" },
	{ value: "es_ES", label: "Испания - Испанский" },
	{ value: "en_LK", label: "шри - ланка - Английский" },
	{ value: "en_ZA", label: "ЮАР - Английский" },
	{ value: "en_SI", label: "Словения - Английский" },
	{ value: "sv_SE", label: "Швеция - Шведский" },
	{ value: "fr_CH", label: "Швейцария - Французский" },
	{ value: "de_CH", label: "Швейцария - Немецкий" },
  { value: "zhtr_TW", label: "Тайвань - Китайский (традиционное письмо)" },
	{ value: "en_TH", label: "Таиланд - Английский" },
	{ value: "th_TH", label: "Таиланд - Тайский" },
	{ value: "en_TT", label: "Тринидад и Тобаго - Английский" },
	{ value: "tr_TR", label: "Турция - Турецкий" },
  { value: "en_AE", label: "Объединенные Арабские Эмираты - Английский" },
	{ value: "en_GB", label: "Великобритания - Английский" },
	{ value: "es_UY", label: "Уругвай - Испанский" },
	{ value: "es_VE", label: "Венесуэла - Испанский" },
	{ value: "en_VN", label: "Вьетнам - Английский" },
    { value: "en_SK", label: "Словакия - Английский"},

]

export const primaryUse = [
  { value: "", label: "Выберите вариант:" },
	{ value: "002", label: "Личное использование" },
	{ value: "003", label: "Домашний офис" },
	{ value: "005", label: "Предприятие (штат 9 сотрудников или менее)" },
	{ value: "006", label: "Предприятие (штат 10 сотрудников или более)" },
]

export const salutation = [
  { value: "", label: "Выберите вариант:" },
	{ value: "001", label: "Уважаемый" },
	{ value: "003", label: "Уважаемая" },
]

export const jobResponsibility = [
  { value: "", label: "Выберите один вариант" },
	{ value: "041", label: "Помощник администратора" },
	{ value: "030", label: "Профессионал в области бизнеса" },
	{ value: "035", label: "Специалист по системам САПР/САМ" },
	{ value: "033", label: "Владелец/президент компании" },
	{ value: "055", label: "Консультант/системный интегратор" },
	{ value: "034", label: "Руководитель отдела" },
	{ value: "046", label: "Инженер/технический специалист" },
	{ value: "054", label: "Исполнительный руководитель/член правления" },
	{ value: "036", label: "Менеджер по оборудованию" },
	{ value: "047", label: "Специалист по финансам/учету" },
	{ value: "031", label: "Менеджер по финансам/финансовый директор" },
	{ value: "037", label: "Специалист по графическому дизайну" },
	{ value: "032", label: "Руководитель ИТ-отдела (директор по информатизации/главный технический директор)" },
	{ value: "051", label: "ИТ-технолог" },
	{ value: "049", label: "Специалист по маркетингу" },
	{ value: "045", label: "Менеджер группы/отдела ИТ/ИС" },
	{ value: "044", label: "Специалист по ИТ/ИС" },
	{ value: "042", label: "Офис-менеджер" },
	{ value: "043", label: "Специалист по закупкам/снабжению" },
	{ value: "050", label: "Специалист по продажам" },
	{ value: "040", label: "Сотрудник отдела обслуживания/поддержки" },
	{ value: "048", label: "Разработчик ПО" },
	{ value: "052", label: "Школьный инспектор/директор школы" },
	{ value: "053", label: "Учитель" },
	{ value: "000", label: "Другое" },
]
