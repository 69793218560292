export const SETTINGS = {
  server: {
    api: process.env.REACT_APP_API_URL,
    api_products: process.env.REACT_APP_API_PRODUCTS_URL,
    port: '443',
    search: 'search',
    fetch: 'fetchData',
    searchProduct: 'role/searchProductDetails',
    index: {
      role: process.env.REACT_APP_ROLE_INDEX,
      products: process.env.REACT_APP_PRODUCTS_INDEX,
    },
  },
  captcha: {
    sitekey: process.env.REACT_APP_CAPTCHA_KEY,
  },
  storeKeys: {},
  safetyRecallCountries: {
    "ES": true,
    "US": false,
    "AR": false,
    "AW": false,
    "AU": false,
    "AT": true,
    "BS": false,
    "BD": false,
    "BB": false,
    "BE": true,
    "BO": false,
    "BR": false,
    "BG": true,
    "CA": false,
    "CL": false,
    "CN": false,
    "CO": false,
    "CR": false,
    "CZ": true,
    "DK": true,
    "DO": false,
    "EC": false,
    "EE": true,
    "EG": false,
    "SV": false,
    "FI": true,
    "FR": true,
    "DE": true,
    "GR": true,
    "GD": false,
    "GT": false,
    "HN": false,
    "HK": false,
    "HK": false,
    "HU": true,
    "IN": false,
    "ID": false,
    "ID": false,
    "IE": true,
    "IL": false,
    "IT": true,
    "JM": false,
    "JP": false,
    "KR": false,
    "KW": false,
    "LB": false,
    "LT": true,
    "LU": false,
    "LV": true,
    "MY": false,
    "MX": false,
    "MA": false,
    "NL": true,
    "NZ": false,
    "NI": false,
    "NO": false,
    "PK": false,
    "PA": false,
    "PY": false,
    "PE": false,
    "PH": false,
    "PL": true,
    "PT": true,
    "PR": false,
    "RO": true,
    "RU": false,
    "SA": false,
    "SG": false,
    "LK": false,
    "ZA": false,
    "SI": true,
    "SK": true,
    "SE": true,
    "CH": true,
    "TW": false,
    "TH": false,
    "TH": false,
    "TT": false,
    "TR": false,
    "AE": false,
    "GB": false,
    "UY": false,
    "VE": false,
    "VN": false
  }
};

export default SETTINGS;
