export const country_region = [
	{ value: "en_US", label: "Etats-Unis - Anglais" },
	{ value: "en_ZA", label: "Afrique du Sud - Anglais" },
	{ value: "de_DE", label: "Allemagne - Allemand" },
	{ value: "en_SA", label: "Arabie Saoudite - Anglais" },
    { value: "es_AR", label: "Argentine - Espagnol" },
	{ value: "en_AW", label: "Aruba - Anglais" },
	{ value: "en_AU", label: "Australie - Anglais" },
	{ value: "de_AT", label: "Autriche - Allemand" },
	{ value: "en_BS", label: "Bahamas - Anglais" },
	{ value: "en_BD", label: "Bangladesh - Anglais" },
	{ value: "en_BB", label: "Barbade - Anglais" },
	{ value: "nl_BE", label: "Belgique - Néerlandais" },
	{ value: "fr_BE", label: "Belgique - Français" },
	{ value: "es_BO", label: "Bolivie - Espagnol" },
	{ value: "pt_BR", label: "Brésil - Portugais" },
	{ value: "en_BG", label: "Bulgarie - Anglais" },
	{ value: "en_CA", label: "Canada - Anglais" },
	{ value: "fr_CA", label: "Canada - Français" },
	{ value: "es_CL", label: "Chili - Espagnol" },
	{ value: "zh_CN", label: "Chine - Chinois simplifié" },
	{ value: "es_CO", label: "Colombie - Espagnol" },
	{ value: "ko_KR", label: "Corée - Coréen" },
	{ value: "es_CR", label: "Costa Rica - Espagnol" },
	{ value: "da_DK", label: "Danemark - Danois" },
	{ value: "es_EC", label: "Equateur - Espagnol" },
	{ value: "en_EG", label: "Egypte - Anglais" },
	{ value: "en_AE", label: "Emirats Arabes Unis - Anglais" },
	{ value: "es_ES", label: "Espagne - Espagnol" },
    { value: "en_EE", label: "Estonie - Anglais"},
	{ value: "ru_RU", label: "Fédération de Russie - Russe" },
	{ value: "fi_FI", label: "Finlande - Finnois" },
	{ value: "fr_FR", label: "France - Français" },
	{ value: "el_GR", label: "Grèce - Grec" },
	{ value: "en_GD", label: "Grenade - Anglais" },
	{ value: "es_GT", label: "Guatemala - Espagnol" },
	{ value: "es_HN", label: "Honduras - Espagnol" },
	{ value: "en_HK", label: "Hong Kong - Anglais" },
	{ value: "zhtr_HK", label: "Hong-Kong - Chinois traditionnel" },
	{ value: "hu_HU", label: "Hongrie - Hongrois" },
	{ value: "en_IN", label: "Inde - Anglais" },
	{ value: "en_ID", label: "Indonésie - Anglais" },
	{ value: "in_ID", label: "Indonésie - Indonésienne" },
	{ value: "en_IE", label: "Irlande - Anglais" },
	{ value: "en_IL", label: "Israël - Anglais" },
	{ value: "it_IT", label: "Italie - Italien" },
	{ value: "en_JM", label: "Jamaïque - Anglais" },
	{ value: "ja_JP", label: "Japon - Japonais" },
	{ value: "en_KW", label: "Koweït - Anglais" },
    { value: "en_LV", label: "Lettonie - Anglais"},
	{ value: "en_LB", label: "Liban - Anglais" },
    { value: "en_LT", label: "Lituanie - Anglais"},
	{ value: "fr_LU", label: "Luxembourg - Français" },
	{ value: "en_MY", label: "Malaisie - Anglais" },
	{ value: "es_MX", label: "Mexique - Espagnol" },
	{ value: "en_MA", label: "Maroc - Anglais" },
	{ value: "en_NZ", label: "Nouvelle-Zélande - Anglais" },
	{ value: "es_NI", label: "Nicaragua - Espagnol" },
	{ value: "no_NO", label: "Norvège - Norvégien" },
	{ value: "en_PK", label: "Pakistan - Anglais" },
	{ value: "es_PA", label: "Panama - Espagnol" },
	{ value: "es_PY", label: "Paraguay - Espagnol" },
	{ value: "nl_NL", label: "Pays-Bas - Néerlandais" },
	{ value: "es_PE", label: "Pérou - Espagnol" },
	{ value: "en_PH", label: "Philippines - Anglais" },
	{ value: "pl_PL", label: "Pologne - Polonais" },
	{ value: "pt_PT", label: "Portugal - Portugais" },
	{ value: "es_PR", label: "Porto Rico - Espagnol" },
	{ value: "es_DO", label: "République Dominicaine - Espagnol" },
	{ value: "cs_CZ", label: "République Tchèque - Tchèque" },
	{ value: "en_GB", label: "Royaume-Uni - Anglais" },
	{ value: "en_RO", label: "Roumanie - Anglais" },
	{ value: "es_SV", label: "Salvador - Espagnol" },
	{ value: "en_SG", label: "Singapour - Anglais" },
	{ value: "en_LK", label: "Sri Lanka - Anglais" },
    { value: "en_SK", label: "Slovaquie - Anglais"},
	{ value: "en_SI", label: "Slovénie - Anglais" },
	{ value: "sv_SE", label: "Suède - Suédois" },
	{ value: "fr_CH", label: "Suisse - Français" },
	{ value: "de_CH", label: "Suisse - Allemand" },
	{ value: "zhtr_TW", label: "Taiwan - Chinois traditionnel" },
	{ value: "en_TH", label: "Thaïlande - Anglais" },
	{ value: "th_TH", label: "Thaïlande - Thai" },
	{ value: "en_TT", label: "Trinité-et-Tobago - Anglais" },
	{ value: "tr_TR", label: "Turquie - Turc" },
	{ value: "es_UY", label: "Uruguay - Espagnol" },
	{ value: "es_VE", label: "Venezuela - Espagnol" },
	{ value: "en_VN", label: "Viet Nam - Anglais" },

]

export const primaryUse = [
  { value: "", label: "Sélectionnez une réponse" },
	{ value: "002", label: "Utilisation personnelle" },
	{ value: "003", label: "Travail à domicile" },
	{ value: "005", label: "Entreprise de moins de 9 personnes" },
	{ value: "006", label: "Entreprise de 10 personnes ou plus" },
]

export const salutation = [
  { value: "", label: "Sélectionnez une réponse" },
	{ value: "001", label: "M." },
	{ value: "002", label: "Mme." },
	{ value: "005", label: "Mlle." },
]

export const jobResponsibility = [
  { value: "", label: "Sélectionnez Un" },
	{ value: "041", label: "Assistant de gestion" },
	{ value: "030", label: "Commercial" },
	{ value: "035", label: "Professionnel CFAO" },
	{ value: "033", label: "PDG/Gérant ou directeur d'entreprise" },
	{ value: "055", label: "Consultant / Intégrateur systèmes" },
	{ value: "034", label: "Chef de service" },
	{ value: "046", label: "Ingénieur/Technicien" },
	{ value: "054", label: "Cadre/Membre du Conseil" },
	{ value: "036", label: "Responsable des installations" },
	{ value: "047", label: "Cadre financier/Cadre comptable" },
	{ value: "031", label: "Agent/Directeur financier" },
	{ value: "037", label: "Professionnel des arts graphiques" },
	{ value: "032", label: "Cadre dans l'informatique (Directeur de l'information/Directeur des technologies)" },
	{ value: "051", label: "Stratège informatique" },
	{ value: "049", label: "Responsable marketing" },
	{ value: "045", label: "Responsable de groupe/dépt. informatique de gestion/informatique" },
	{ value: "044", label: "Responsable informatique, informaticien" },
	{ value: "042", label: "Responsable administratif" },
	{ value: "043", label: "Responsable des achats/de l'approvisionnement" },
	{ value: "050", label: "Responsable des ventes" },
	{ value: "040", label: "Service/Assistance/Centre d'appel" },
	{ value: "048", label: "Développeur logiciel" },
	{ value: "052", label: "Proviseur/Principal/Directeur d'école" },
	{ value: "053", label: "Professeur" },
	{ value: "000", label: "Autres" },
]
