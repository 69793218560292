export const country_region = [
	{ value: "en_US", label: "미국 - 영어" },
  { value: "en_AA", label: "아프리카 - 영어" },
	{ value: "fr_AA", label: "아프리카 - 프랑스어" },
	{ value: "es_AR", label: "아르헨티나 - 스페인어" },
	{ value: "en_AU", label: "호주 - 영어" },
	{ value: "de_AT", label: "오스트리아 - 독일어" },
	{ value: "be_BY", label: "벨로루시 - 러시아어" },
	{ value: "nl_BE", label: "벨기에 - 네덜란드어" },
	{ value: "fr_BE", label: "룩셈부르크 - 프랑스어" },
	{ value: "es_BO", label: "볼리비아 - 스페인어" },
	{ value: "pt_BR", label: "브라질 - 포르투갈어" },
	{ value: "bg_BG", label: "불가리아 - 불가리아어" },
	{ value: "en_BG", label: "불가리아 - 영어" },
	{ value: "en_CA", label: "캐나다 - 영어" },
	{ value: "fr_CA", label: "캐나다 - 프랑스어" },
	{ value: "en_CB", label: "카리브 해 - 영어" },
	{ value: "es_NS", label: "중앙 아메리카 - 스페인어" },
	{ value: "es_CL", label: "칠레 - 스페인어" },
	{ value: "zh_CN", label: "중국 - 중국어 간체" },
	{ value: "es_CO", label: "콜롬비아 - 스페인어" },
	{ value: "hr_HR", label: "크로아티아 - 크로아티아어" },
	{ value: "cs_CZ", label: "체코 - 체코어" },
	{ value: "da_DK", label: "덴마크 - 덴마크어" },
	{ value: "es_EC", label: "에콰도르 - 스페인어" },
	{ value: "et_EE", label: "에스토니아 - 에스토니아어" },
    { value: "en_EE", label: "에스토니아 - 영어"},
	{ value: "fi_FI", label: "핀란드 - 핀란드어" },
	{ value: "fr_FR", label: "프랑스 - 프랑스어" },
	{ value: "de_DE", label: "독일 - 독일어" },
	{ value: "el_GR", label: "그리스 - 그리스어" },
	{ value: "en_HK", label: "홍콩 - 영어" },
	{ value: "zhtr_HK", label: "홍콩 - 중국어 번체" },
	{ value: "hu_HU", label: "헝가리 - 헝가리어" },
	{ value: "en_IN", label: "인도 - 영어" },
	{ value: "en_ID", label: "인도네시아 - 영어" },
	{ value: "in_ID", label: "인도네시아  -  바하사" },
	{ value: "en_IE", label: "아일랜드 - 영어" },
	{ value: "he_IL", label: "이스라엘 - 히브리어" },
	{ value: "it_IT", label: "이탈리아 - 이탈리아어" },
	{ value: "ja_JP", label: "일본 - 일본어" },
	{ value: "ru_KZ", label: "카자흐스탄 - 러시아어" },
	{ value: "ko_KR", label: "한국 - 한국어" },
	{ value: "lv_LV", label: "라트비아 - 라트비아어" },
	{ value: "lt_LT", label: "리투아니아 - 리투아니아어" },
    { value: "en_LV", label: "라트비아 - 영어"},
	{ value: "en_LB", label: "레바논 - 영어" },
    { value: "en_LT", label: "리투아니아 - 영어"},
	{ value: "en_MY", label: "말레이시아 - 영어" },
	{ value: "es_MX", label: "멕시코 - 스페인어" },
	{ value: "en_ME", label: "중동 - 영어" },
	{ value: "nl_NL", label: "네덜란드 - 네덜란드어" },
	{ value: "en_NZ", label: "뉴질랜드 - 영어" },
	{ value: "no_NO", label: "노르웨이 - 노르웨이어" },
	{ value: "en_PK", label: "파키스탄 - 영어" },
	{ value: "es_PY", label: "파라과이 - 스페인어" },
	{ value: "es_PE", label: "페루 - 스페인어" },
	{ value: "en_PH", label: "필리핀 - 영어" },
	{ value: "pl_PL", label: "폴란드 - 폴란드어" },
	{ value: "pt_PT", label: "포르투갈 - 포르투갈어" },
	{ value: "es_PR", label: "푸에르토리코 - 스페인어" },
	{ value: "en_BD", label: "방글라데시 - 영어" },
	{ value: "en_RO", label: "루마니아 - 영어" },
	{ value: "ru_RU", label: "러시아 - 러시아어" },
	{ value: "en_SA", label: "사우디 아라비아 - 영어" },
	{ value: "sr_YU", label: "세르비아 - 세르비아어" },
	{ value: "en_SG", label: "싱가포르 - 영어" },
	{ value: "es_ES", label: "스페인 - 스페인어" },
	{ value: "sk_SK", label: "슬로바키아 - 슬로바키아어" },
	{ value: "en_SI", label: "슬로베니아 - 영어" },
	{ value: "en_ZA", label: "남아프리카 - 영어" },
	{ value: "en_LK", label: "스리랑카 - 영어" },
	{ value: "sv_SE", label: "스웨덴 - 스웨덴어" },
	{ value: "fr_CH", label: "위스 - 프랑스어" },
	{ value: "de_CH", label: "스위스 - 독일어" },
	{ value: "zhtr_TW", label: "대만 - 중국어 번체" },
	{ value: "en_TH", label: "타이 - 영어" },
	{ value: "th_TH", label: "태국  -  태국어" },
	{ value: "tr_TR", label: "터키 - 터키어" },
	{ value: "uk_UA", label: "우크라이나 - 러시아어" },
	{ value: "en_AE", label: "아랍 에미리트 연합국 - 영어" },
	{ value: "en_GB", label: "영국 - 영어" },
	{ value: "es_UY", label: "우루과이 - 스페인어" },
	{ value: "es_VE", label: "베네수엘라 - 스페인어" },
	{ value: "en_VN", label: "베트남 - 영어" },
    { value: "en_SK", label: "슬로바키아 - 영어"},
]

export const purchaseLocation = [
  { value: "", label: "하나를 선택하십시오." },
	{ value: "337", label: "용산 전자상가" },
	{ value: "338", label: "hp 대리점(디지털 hp)" },
	{ value: "339", label: "인터넷 쇼핑몰" },
	{ value: "340", label: "hp 온라인 구매점" },
	{ value: "341", label: "일반 컴퓨터 소매점" },
	{ value: "000", label: "기타" },
];

export const primaryUse = [
  { value: "", label: "선택해 주십시오." },
	{ value: "002", label: "개인용" },
	{ value: "003", label: "재택 근무 사업체" },
	{ value: "005", label: "직원 9명 이하 사업체" },
	{ value: "006", label: "직원 10명 이상 사업체" },
]

export const salutation = [
	{ value: "", label:"Select One" },
	{ value: "001", label:"Mr." },
	{ value: "002", label:"Mrs." },
	{ value: "005", label:"Miss" },
]

export const occupation = [
  { value: "", label: "선택해 주십시오." },
	{ value: "11", label: "관리직(마케팅,광고,이벤트)" },
	{ value: "13", label: "경영,회계직" },
	{ value: "15", label: "컴퓨터설계 및 컴퓨터전문직" },
	{ value: "17", label: "건축 및 토목설계" },
	{ value: "19", label: "과학자,연구원" },
	{ value: "21", label: "사회복지업" },
	{ value: "23", label: "법률직" },
	{ value: "25", label: "교육직" },
	{ value: "27", label: "예술관련 (미술,디자인,연예,스포츠)" },
	{ value: "29", label: "의사" },
	{ value: "31", label: "간호사" },
	{ value: "33", label: "보안 및 경호직" },
	{ value: "35", label: "숙박 및 음식업" },
	{ value: "37", label: "건물 및 환경미화, 용역" },
	{ value: "39", label: "개인서비스직 (보육,여행가이드 등)" },
	{ value: "41", label: "영업직" },
	{ value: "43", label: "사무직" },
	{ value: "45", label: "농축업,임업,어업" },
	{ value: "47", label: "건설업(노동)" },
	{ value: "503", label: "공무원" },
	{ value: "504", label: "군인" },
	{ value: "51", label: "제조업" },
	{ value: "995", label: "기타" },
];

export const jobResponsibility = [
  { value: "", label: "선택해 주십시오" },
	{ value: "031", label: "간호사" },
	{ value: "033", label: "보안 및 경호직" },
	{ value: "035", label: "숙박 및 음식업" },
	{ value: "037", label: "건물 및 환경미화,용역" },
	{ value: "041", label: "영업직" },
	{ value: "043", label: "사무직" },
	{ value: "045", label: "농축업,임업,어업" },
	{ value: "047", label: "건설업" },
	{ value: "051", label: "제조업" },
	{ value: "053", label: "운송업" },
	{ value: "000", label: "기타" },
]
