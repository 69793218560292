import * as cs from './cs';
import * as da from './da';
import * as de from './de';
import * as el from './el';
import * as en from './en';
import * as es from './es';
import * as fi from './fi';
import * as fr from './fr';
import * as hu from './hu';
import * as it from './it';
import * as ja from './ja';
import * as ko from './ko';
import * as nl from './nl';
import * as no from './no';
import * as pl from './pl';
import * as pt from './pt';
import * as ru from './ru';
import * as sv from './sv';
import * as tr from './tr';
import * as zh from './zh';
import * as zhtr from './zhtr';

export const getConstData = (field, lng = 'en') => {
    const data = { cs, da, de, el, en, es, fi, fr, hu, it, ja, ko, nl, no, pl, pt, ru, sv, tr, zh, zhtr };

    if (!field || !lng) {
        return [];
    }

    const select_lng = data[lng] ? lng : 'en';
    return data[select_lng][field] || data.en[field] || [];
};
