export const country_region = [
	{ value: "en_US", label: "USA - engelska" },
  { value: "es_AR", label: "Argentina - spanska" },
	{ value: "en_AW", label: "Aruba - engelska" },
	{ value: "en_AU", label: "Australien - engelska" },
	{ value: "en_BS", label: "Bahamas - engelska" },
	{ value: "en_BD", label: "Bangladesh - engelska" },
	{ value: "en_BB", label: "Barbados - engelska" },
	{ value: "nl_BE", label: "Belgien - nederländska" },
	{ value: "fr_BE", label: "Belgien - franska" },
	{ value: "es_BO", label: "Bolivia - spanska" },
	{ value: "pt_BR", label: "Brasilien - portugisiska" },
	{ value: "en_BG", label: "Bulgarien - engelska" },
	{ value: "es_CL", label: "Chile - spanska" },
	{ value: "es_CO", label: "Colombia - spanska" },
	{ value: "es_CR", label: "Costa Rica - spanska" },
	{ value: "da_DK", label: "Danmark - danska" },
	{ value: "es_DO", label: "Dominikanska republiken - spanska" },
  { value: "es_EC", label: "Ecuador - spanska" },
	{ value: "en_EG", label: "Egypten - engelska" },
	{ value: "es_SV", label: "El Salvador - spanska" },
    { value: "en_EE", label: "Estland - engelska"},
	{ value: "en_PH", label: "Filippinerna - engelska" },
	{ value: "fi_FI", label: "Finland - finska" },
	{ value: "fr_FR", label: "Frankrike - franska" },
	{ value: "en_AE", label: "Förenade Arabemiraten - engelska" },
	{ value: "el_GR", label: "Grekland - grekiska" },
	{ value: "en_GD", label: "Grenada - engelska" },
	{ value: "es_GT", label: "Guatemala - spanska" },
  { value: "es_HN", label: "Honduras - spanska" },
	{ value: "en_HK", label: "Hong Kong - engelska" },
	{ value: "zhtr_HK", label: "Hong Kong - traditionell kinesiska" },
	{ value: "en_IN", label: "Indien - engelska" },
	{ value: "en_ID", label: "Indonesien - engelska" },
	{ value: "in_ID", label: "Indonesien - Indonesiska" },
	{ value: "en_IE", label: "Israel - engelska" },
	{ value: "it_IT", label: "Italien - italienska" },
  { value: "en_JM", label: "Jamaica - engelska" },
	{ value: "ja_JP", label: "Japan - japanska" },
	{ value: "en_CA", label: "Kanada - engelska" },
	  { value: "fr_CA", label: "Kanada - franska" },
	  { value: "zh_CN", label: "Kina - förenklad kinesiska" },
	{ value: "ko_KR", label: "Korea - koreanska" },
	{ value: "en_KW", label: "Kuwait - engelska" },
    { value: "en_LV", label: "Lettland - engelska"},
	{ value: "en_LB", label: "Libanon - Engelska" },
    { value: "en_LT", label: "Litauen - engelska"},
	{ value: "fr_LU", label: "Luxemburg - franska" },
  { value: "en_MY", label: "Malaysia - engelska" },
	{ value: "es_MX", label: "Mexico - spanska" },
	{ value: "en_MA", label: "Marocko - engelska" },
	{ value: "nl_NL", label: "Nederländerna - nederländska" },
	{ value: "en_NZ", label: "Nya Zeeland - engelska" },
	{ value: "es_NI", label: "Nicaragua - spanska" },
	{ value: "no_NO", label: "Norge - norska" },
  { value: "en_PK", label: "Pakistan - engelska" },
	{ value: "es_PA", label: "Panama - spanska" },
	{ value: "es_PY", label: "Paraguay - spanska" },
	{ value: "es_PE", label: "Peru - spanska" },
	{ value: "pl_PL", label: "Polen - polska" },
	{ value: "pt_PT", label: "Portugal - portugisiska" },
	{ value: "es_PR", label: "Puerto Rico - spanska" },
  { value: "en_RO", label: "Rumänien - engelska" },
	{ value: "ru_RU", label: "Ryska federationen - ryska" },
	{ value: "en_SA", label: "Saudiarabien - engelska" },
	{ value: "en_SG", label: "Singapore - engelska" },
	{ value: "es_ES", label: "Spanien - spanska" },
	{ value: "en_LK", label: "Sri Lanka - engelska" },
	{ value: "en_ZA", label: "Sydafrika - engelska" },
    { value: "en_SK", label: "Slovakien - engelska"},
	{ value: "en_SI", label: "Slovenien - engelska" },
	{ value: "en_GB", label: "Storbritannien - engelska" },
	{ value: "sv_SE", label: "Sverige - svenska" },
	{ value: "fr_CH", label: "Schweiz - franska" },
	{ value: "de_CH", label: "Schweiz - tyska" },
  { value: "zhtr_TW", label: "Taiwan - traditionell kinesiska" },
	{ value: "en_TH", label: "Thailand - engelska" },
	{ value: "th_TH", label: "Thailand - Thai" },
	{ value: "cs_CZ", label: "Tjeckien - tjeckiska" },
	{ value: "en_TT", label: "Trinidad och Tobago - engelska" },
	{ value: "tr_TR", label: "Turkiet - turkiska" },
	{ value: "de_DE", label: "Tyskland - tyska" },
	{ value: "hu_HU", label: "Ungern - ungerska" },
	{ value: "es_UY", label: "Uruguay - spanska" },
	{ value: "es_VE", label: "Venezuela - spanska" },
	{ value: "en_VN", label: "Vietnam - engelska" },
	{ value: "de_AT", label: "Österrike - tyska" },
]

export const primaryUse = [
  { value: "", label: "Välj en" },
	{ value: "002", label: "Privat användning" },
	{ value: "003", label: "Hemarbete" },
	{ value: "005", label: "Företag med 9 anställda eller färre" },
	{ value: "006", label: "Företag med 10 anställda eller fler" },
]

export const salutation = [
	{ value: "", label:"Select One" },
	{ value: "001", label:"Mr." },
	{ value: "002", label:"Mrs." },
	{ value: "005", label:"Miss" },
]

export const jobResponsibility = [
  { value: "", label: "Välj ett alternativ" },
	{ value: "041", label: "Administrativ assistent" },
	{ value: "030", label: "Företagare" },
	{ value: "035", label: "CAD/CAM-ingenjör" },
	{ value: "033", label: "Företagets ägare/Vd" },
	{ value: "055", label: "Konsult/Systemintegratör" },
	{ value: "034", label: "Avdelningschef" },
	{ value: "046", label: "Civilingenjör/Tekniker" },
	{ value: "054", label: "Företagsledare/Styrelseledamot" },
	{ value: "036", label: "Platschef" },
	{ value: "047", label: "Ekonomi/redovisningspersonal" },
	{ value: "031", label: "Ekonomichef" },
	{ value: "037", label: "Grafiker" },
	{ value: "032", label: "IT-chef (Informationschef/teknisk chef)" },
	{ value: "051", label: "IT-strateg" },
	{ value: "049", label: "Marknadsförare" },
	{ value: "045", label: "Grupp- eller avdelningschef, IS/IT" },
	{ value: "044", label: "IS/IT-personal" },
	{ value: "042", label: "Kontorschef" },
	{ value: "043", label: "Inköpare" },
	{ value: "050", label: "Säljare" },
	{ value: "040", label: "Service/Support/Help Desk" },
	{ value: "048", label: "Programmerare" },
	{ value: "052", label: "Inspektör/Rektor" },
	{ value: "053", label: "Lärare" },
	{ value: "000", label: "Annat" },
]
