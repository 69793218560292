export const country_region = [
	{ value:"en_US", label:"美国 - 英语" },
	{ value:"zhtr_HK", label:"中國香港特別行政區 - 繁体中文" },
	{ value:"zhtr_TW", label:"臺灣 地區 - 繁体中文" }
]

export const primaryUse = [
	{ value: "", label:"请选择" },
	{ value: "002", label:"个人使用" },
	{ value: "003", label:"家庭办公用" },
	{ value: "005", label:"雇员为1-9人的公司使用" },
	{ value: "006", label:"雇员为10人以上的公司使用" }
]

export const salutation = [
	{ value: "", label:"选择一个" },
	{ value: "001", label:"先生" },
	{ value: "002", label:"女士" },
	{ value: "003", label:"小姐" }
]

export const lifestylesandhobbies = [
	{ value: "", label:"选择一个" },
	{ value: "003", label:"摄影" },
	{ value: "010", label:"旅行" },
	{ value: "005", label:"家居" },
	{ value: "011", label:"教育" },
	{ value: "006", label:"聚会" },
	{ value: "012", label:"网络游戏" },
	{ value: "007", label:"住宅美化" },
	{ value: "013", label:"音乐" },
	{ value: "008", label:"运动" },
	{ value: "014", label:"电影" },
	{ value: "009", label:"宠物" },
	{ value: "015", label:"志愿工作" }
]

export const occupation = [
	{ value: "", label:"请选择" },
	{ value: "11", label:"管理(主任,经理等)" },
	{ value: "13", label:"财务会计" },
	{ value: "15", label:"计算机技术" },
	{ value: "17", label:"建築工程" },
	{ value: "21", label:"社会服务" },
	{ value: "23", label:"法务" },
	{ value: "25", label:"教师" },
	{ value: "27", label:"美工绘图" },
	{ value: "29", label:"医师" },
	{ value: "41", label:"业务销售" },
	{ value: "43", label:"一般行政" },
	{ value: "45", label:"农林牧" },
	{ value: "501", label:"家庭主妇" },
	{ value: "502", label:"学生" },
	{ value: "503", label:"政府" },
	{ value: "504", label:"军队" },
	{ value: "53", label:"物流运输" },
	{ value: "995", label:"其他" }
]

export const state_cd = [
	{ value: "", label:"请选择" },
	{ value: "北京", label:"北京" },
	{ value: "澳门", label:"澳门" },
	{ value: "天津", label:"天津" },
	{ value: "上海", label:"上海" },
	{ value: "重庆", label:"重庆" },
	{ value: "河北", label:"河北" },
	{ value: "山西", label:"山西" },
	{ value: "内蒙古", label:"内蒙古" },
	{ value: "辽宁", label:"辽宁" },
	{ value: "吉林", label:"吉林" },
	{ value: "黑龙江", label:"黑龙江" },
	{ value: "河南", label:"河南" },
	{ value: "新疆", label:"新疆" },
	{ value: "江苏", label:"江苏" },
	{ value: "浙江", label:"浙江" },
	{ value: "山东", label:"山东" },
	{ value: "安徽", label:"安徽" },
	{ value: "江西", label:"江西" },
	{ value: "湖北", label:"湖北" },
	{ value: "四川", label:"四川" },
	{ value: "云南", label:"云南" },
	{ value: "陕西", label:"陕西" },
	{ value: "甘肃", label:"甘肃" },
	{ value: "青海", label:"青海" },
	{ value: "宁夏", label:"宁夏" },
	{ value: "西藏", label:"西藏" },
	{ value: "广东", label:"广东" },
	{ value: "广西", label:"广西" },
	{ value: "福建", label:"福建" },
	{ value: "湖南", label:"湖南" },
	{ value: "海南", label:"海南" },
	{ value: "贵州", label:"贵州" },
	{ value: "香港", label:"香港" }
]
