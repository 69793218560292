export const country_region = [
	{ value: "en_US", label: "Yhdysvallat - englanti" },
	{ value: "nl_NL", label: "Alankomaat - hollanti" },
	{ value: "en_AE", label: "Arabiemiirikunnat" },
    { value: "es_AR", label: "Argentiina - espanja" },
	{ value: "en_AW", label: "Aruba - englanti" },
	{ value: "en_AU", label: "Australia - englanti" },
	{ value: "en_BS", label: "Bahama - englanti" },
	{ value: "en_BD", label: "Bangladesh - englanti" },
	{ value: "en_BB", label: "Barbados - englanti" },
	{ value: "nl_BE", label: "Belgia - hollanti" },
	{ value: "fr_BE", label: "Belgia - ranska" },
	{ value: "es_BO", label: "Bolivia - espanja" },
	{ value: "pt_BR", label: "Brasilia - portugali" },
	{ value: "en_BG", label: "Bulgaria - englanti" },
	{ value: "es_CL", label: "Chile - espanja" },
	{ value: "es_CR", label: "Costa Rica - espanja" },
	{ value: "es_DO", label: "Dominikaaninen tasavalta - espanja" },
	{ value: "es_EC", label: "Ecuador - espanja" },
	{ value: "en_EG", label: "Egypti - englanti" },
	{ value: "es_SV", label: "El Salvador - espanja" },
	{ value: "es_ES", label: "Espanja - espanja" },
	{ value: "en_ZA", label: "Etelä - Afrikka - englanti" },
	{ value: "en_PH", label: "Filippiinit - englanti" },
	{ value: "en_GD", label: "Grenada - englanti" },
	{ value: "es_GT", label: "Guatemala - espanja" },
	{ value: "es_HN", label: "Honduras - espanja" },
	{ value: "en_HK", label: "Hong Kong - englanti" },
	{ value: "zhtr_HK", label: "Hongkong - perinteinen kiina" },
	{ value: "en_IN", label: "Intia - englanti" },
	{ value: "en_ID", label: "Indonesia - englanti" },
	{ value: "in_ID", label: "Indonesia - Indonesialainen" },
	{ value: "en_IE", label: "Irlanti - englanti" },
	{ value: "en_GB", label: "Iso - Britannia - englanti" },
	{ value: "en_IL", label: "Israel - englanti" },
	{ value: "it_IT", label: "Italia - italia" },
	{ value: "de_AT", label: "Itävalta - saksa" },
	{ value: "en_JM", label: "Jamaika - englanti" },
	{ value: "ja_JP", label: "Japani - japani" },
	{ value: "en_CA", label: "Kanada - englanti" },
	{ value: "fr_CA", label: "Kanada - ranska" },
	{ value: "zh_CN", label: "Kiina - yksinkertaistettu kiina" },
	{ value: "es_CO", label: "Kolumbia - espanja" },
	{ value: "ko_KR", label: "Korea - korea" },
	{ value: "el_GR", label: "Kreikka - kreikka" },
	{ value: "en_KW", label: "Kuwait - englanti" },
    { value: "en_LV", label: "Latvia - englanti"},
	{ value: "en_LB", label: "Libanon - englanti" },
    { value: "en_LT", label: "Liettua - englanti"},
	{ value: "fr_LU", label: "Luxemburg - ranska" },
	{ value: "en_MY", label: "Malesia - englanti" },
	{ value: "es_MX", label: "Meksiko - espanja" },
	{ value: "en_MA", label: "Marokko - englanti" },
	{ value: "es_NI", label: "Nicaragua - espanja" },
	{ value: "no_NO", label: "Norja - norja" },
	{ value: "en_PK", label: "Pakistan - englanti" },
	{ value: "es_PA", label: "Panama - espanja" },
	{ value: "es_PY", label: "Paraguay - espanja" },
	{ value: "es_PE", label: "Peru - espanja" },
	{ value: "pl_PL", label: "Puola - puola" },
	{ value: "pt_PT", label: "Portugali - portugali" },
	{ value: "es_PR", label: "Puerto Rico - espanja" },
	{ value: "fr_FR", label: "Ranska - ranska" },
	{ value: "en_RO", label: "Romania - englanti" },
	{ value: "sv_SE", label: "Ruotsi - ruotsi" },
	{ value: "de_DE", label: "Saksa - saksa" },
	{ value: "en_SA", label: "Saudi Arabia - englanti" },
	{ value: "en_SG", label: "Singapore - englanti" },
	{ value: "en_LK", label: "Sri Lankan - englanti" },
    { value: "en_SK", label: "Slovakia - englanti"},
	{ value: "en_SI", label: "Slovenia - englanti" },
	{ value: "fi_FI", label: "Suomi - suomi" },
	{ value: "fr_CH", label: "Sveitsi - ranska" },
	{ value: "de_CH", label: "Sveitsi - saksa" },
	{ value: "zhtr_TW", label: "Taiwan - perinteinen kiina" },
	{ value: "da_DK", label: "Tanska - tanska" },
	{ value: "en_TH", label: "Thaimaa - englanti" },
	{ value: "th_TH", label: "Thaimaa - Thaimaalainen" },
	{ value: "en_TT", label: "Trinidad ja Tobago - englanti" },
	{ value: "cs_CZ", label: "Tšekin tasavalta - tšekki" },
	{ value: "tr_TR", label: "Turkki - turkki" },
	{ value: "hu_HU", label: "Unkari - unkari" },
	{ value: "es_UY", label: "Uruguay - espanja" },
	{ value: "en_NZ", label: "Uusi - Seelanti - englanti" },
	{ value: "es_VE", label: "Venezuela - espanja" },
	{ value: "ru_RU", label: "Venäjä - venäjä" },
    { value: "en_EE", label: "Viro - englanti"},
	{ value: "en_VN", label: "Vietnam - englanti" },

]

export const primaryUse = [
  { value: "", label: "Valitse yksi" },
	{ value: "002", label: "Yksityiskäyttö" },
	{ value: "003", label: "Oma yritys" },
	{ value: "005", label: "Yritys= jossa enintään 9 työntekijää" },
	{ value: "006", label: "Vähintään 10 työntekijän yritys" },
]

export const salutation = [
  { value: "", label: "Valitse yksi" },
	{ value: "001", label: "Hra" },
	{ value: "002", label: "Rva" },
	{ value: "005", label: "Nti" },
]

export const jobResponsibility = [
  { value: "", label: "Valitse yksi" },
	{ value: "041", label: "Johdon avustaja" },
	{ value: "030", label: "Liiketoiminnan ammattilainen" },
	{ value: "035", label: "CAD/CAM-ammattilainen" },
	{ value: "033", label: "Yrityksen omistaja/toimitusjohtaja" },
	{ value: "055", label: "Konsultti/järjestelmäintegraattori" },
	{ value: "034", label: "Osastonjohtaja" },
	{ value: "046", label: "Insinööri/teknikko" },
	{ value: "054", label: "Johtaja/johtokunnan jäsen" },
	{ value: "036", label: "Laiteympäristön ylläpitäjä" },
	{ value: "047", label: "Talous-/kirjanpitoammattilainen" },
	{ value: "031", label: "Talousjohtaja" },
	{ value: "037", label: "Graafikko" },
	{ value: "032", label: "IT-johtaja (CIO/CTO)" },
	{ value: "051", label: "IT-strategi" },
	{ value: "049", label: "Markkinoinnin ammattilainen" },
	{ value: "045", label: "MIS-/IT-ryhmän/osaston johtaja" },
	{ value: "044", label: "MIS-/IT-ammattilainen" },
	{ value: "042", label: "Toimistopäällikkö" },
	{ value: "043", label: "Osto-/hankinta-ammattilainen" },
	{ value: "050", label: "Myyntiammattilainen" },
	{ value: "040", label: "Huolto/tuki/tekninen tuki" },
	{ value: "048", label: "Ohjelmistokehittäjä" },
	{ value: "052", label: "Valvoja/koulunjohtaja" },
	{ value: "053", label: "Opettaja" },
	{ value: "000", label: "Muu" },
]
