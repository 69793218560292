export const country_region = [
	{ value: "en_US", label: "USA - Englisch" },
  	{ value: "es_AR", label: "Argentinien - Spanisch" },
	{ value: "en_AW", label: "Aruba - Englisch" },
	{ value: "en_AU", label: "Australien - Englisch" },
	{ value: "en_BS", label: "Bahamas - Englisch" },
	{ value: "en_BD", label: "Bangladesch - Englisch" },
	{ value: "en_BB", label: "Barbados - Englisch" },
	{ value: "nl_BE", label: "Belgien - Niederländisch" },
	{ value: "fr_BE", label: "Belgien - Französisch" },
	{ value: "es_BO", label: "Bolivien - Spanisch" },
	{ value: "pt_BR", label: "Brasilien - Portugiesisch" },
	{ value: "en_BG", label: "Bulgarien - Englisch" },
  	{ value: "es_CL", label: "Chile - Spanisch" },
	{ value: "zh_CN", label: "China - Chinesisch (vereinfacht)" },
	{ value: "es_CR", label: "Costa Rica - Spanisch" },
	{ value: "da_DK", label: "Dänemark - Dänisch" },
	{ value: "de_DE", label: "Deutschland - Deutsch" },
	{ value: "es_DO", label: "Dominikanische Republik - Spanisch" },
 	{ value: "es_EC", label: "Equador - Spanisch" },
	{ value: "es_SV", label: "El Salvador - Spanisch" },
    { value: "en_EE", label: "Estland - Englisch"},
	{ value: "fi_FI", label: "Finnland - Finnisch" },
	{ value: "fr_FR", label: "Frankreich - Französisch" },
	{ value: "en_GB", label: "Großbritannien - Englisch" },
	{ value: "el_GR", label: "Griechenland - Griechisch" },
	{ value: "en_GD", label: "Grenada - Englisch" },
	{ value: "es_GT", label: "Guatemala - Spanisch" },
  	{ value: "es_HN", label: "Honduras - Spanisch" },
	{ value: "en_HK", label: "Hongkong - Englisch" },
	{ value: "zhtr_HK", label: "Hongkong - Chinesisch (traditionell)" },
	{ value: "hu_HU", label: "Ungarn - Ungarisch" },
	{ value: "en_IN", label: "Indien - Englisch" },
	{ value: "en_ID", label: "Indonesien - Englisch" },
	{ value: "in_ID", label: "Indonesien - Indonesisch" },
	{ value: "en_IE", label: "Irland - Englisch" },
	{ value: "en_IL", label: "Israel - Englisch" },
	{ value: "it_IT", label: "Italien - Italienisch" },
  	{ value: "en_JM", label: "Jamaika - Englisch" },
	{ value: "ja_JP", label: "Japan - Japanisch" },
	{ value: "en_CA", label: "Kanada - Englisch" },
	{ value: "fr_CA", label: "Kanada - Französisch" },
	{ value: "es_CO", label: "Kolumbien - Spanisch" },
	{ value: "ko_KR", label: "Korea - Koreanisch" },
	{ value: "en_KW", label: "Kuwait - Englisch" },
    { value: "en_LV", label: "Lettland - Englisch"},
	{ value: "en_LB", label: "Libanon - Englisch" },
    { value: "en_LT", label: "Litauen - Englisch"},
	{ value: "fr_LU", label: "Luxemburg - Französisch" },
  	{ value: "en_MY", label: "Malaysia - Englisch" },
	{ value: "es_MX", label: "Mexiko - Spanisch" },
	{ value: "en_MA", label: "Marokko - Englisch" },
	{ value: "en_NZ", label: "Neuseeland - Englisch" },
	{ value: "es_NI", label: "Nicaragua - Spanisch" },
	{ value: "nl_NL", label: "Niederlande - Niederländisch" },
	{ value: "no_NO", label: "Norwegen - Norwegisch" },
  	{ value: "en_PK", label: "Pakistan - Englisch" },
	{ value: "es_PA", label: "Panama - Spanisch" },
	{ value: "es_PY", label: "Paraguay - Spanisch" },
	{ value: "es_PE", label: "Peru - Spanisch" },
	{ value: "en_PH", label: "Philippinen - Englisch" },
	{ value: "pl_PL", label: "Polen - Polnisch" },
	{ value: "pt_PT", label: "Portugal - Portugiesisch" },
	{ value: "es_PR", label: "Puerto Rico - Spanisch" },
  	{ value: "en_RO", label: "Rumänien - Englisch" },
	{ value: "ru_RU", label: "Russland - Russisch" },
	{ value: "en_SA", label: "Saudi-Arabien - Englisch" },
	{ value: "en_SG", label: "Singapur - Englisch" },
	{ value: "es_ES", label: "Spanien - Spanisch" },
	{ value: "en_LK", label: "Sri Lanka - Englisch" },
	{ value: "en_ZA", label: "Südafrika - Englisch" },
    { value: "en_SK", label: "Slowakei - Englisch"},
	{ value: "en_SI", label: "Slowenien - Englisch" },
	{ value: "sv_SE", label: "Schweden - Schwedisch" },
	{ value: "fr_CH", label: "Schweiz - Französisch" },
	{ value: "de_CH", label: "Schweiz - Deutsch" },
  	{ value: "zhtr_TW", label: "Taiwan - Chinesisch (traditionell)" },
	{ value: "en_TH", label: "Thailand - Englisch" },
	{ value: "th_TH", label: "Thailand - Thai" },
	{ value: "en_TT", label: "Trinidad und Tobago - Englisch" },
	{ value: "cs_CZ", label: "Tschechische Republik - Tschechisch" },
	{ value: "tr_TR", label: "Türkei - Türkisch" },
  	{ value: "es_UY", label: "Uruguay - Spanisch" },
	{ value: "es_VE", label: "Venezuela - Spanisch" },
	{ value: "en_AE", label: "Vereinigte Arabische Emirate - Englisch" },
	{ value: "en_VN", label: "Vietnam - Englisch" },
	{ value: "en_EG", label: "Ägypten - Englisch" },
	{ value: "de_AT", label: "Österreich - Deutsch" },

]

export const primaryUse = [
  { value: "", label: "Eine Antwort auswählen" },
	{ value: "002", label: "Persönliche Verwendung" },
	{ value: "003", label: "Heimunternehmen" },
	{ value: "005", label: "Unternehmen mit 9 oder weniger Mitarbeitern" },
	{ value: "006", label: "Unternehmen mit 10 oder mehr Mitarbeitern" },
]

export const salutation = [
  { value: "", label: "Eine Antwort auswählen" },
	{ value: "001", label: "Hr." },
	{ value: "002", label: "Frl." },
	{ value: "005", label: "Fr." },
]

export const jobResponsibility = [
  { value: "", label: "Angabe auswählen" },
	{ value: "041", label: "Verwaltungsassistent" },
	{ value: "030", label: "Business Professional" },
	{ value: "035", label: "CAD/CAM-Experte" },
	{ value: "033", label: "Unternehmensinhaber/CEO" },
	{ value: "055", label: "Berater/Systemintegrator" },
	{ value: "034", label: "Abteilungsleiter" },
	{ value: "046", label: "Ingenieur/Techniker" },
	{ value: "054", label: "Executive/Vorstandsmitglied" },
	{ value: "036", label: "Gebäude-/Versorgungsmanager" },
	{ value: "047", label: "Finanz-/Buchhaltungsexperte" },
	{ value: "031", label: "Leiter der Finanzabteilung/CFO" },
	{ value: "037", label: "Grafikdesigner" },
	{ value: "032", label: "IT Executive (CIO/CTO)" },
	{ value: "051", label: "IT-Stratege" },
	{ value: "049", label: "Marketingexperte" },
	{ value: "045", label: "MIS/IT Gruppen-/Abteilungsleiter" },
	{ value: "044", label: "MIS/IT Experte" },
	{ value: "042", label: "Büroleiter" },
	{ value: "043", label: "Mitarbeiter in Einkauf/Beschaffung" },
	{ value: "050", label: "Vertriebsexperte" },
	{ value: "040", label: "Service/Support/Help-Desk" },
	{ value: "048", label: "Software-Entwickler" },
	{ value: "052", label: "Leiter/Direktor" },
	{ value: "053", label: "Lehrer" },
	{ value: "000", label: "Sonstige" },
]
